.btn-menu-nav {
  border-bottom: 1px solid var(--primary-100) !important;
}

.icon-menu-nav {
  font-size: 20px !important;
  color: var(--primary-700) !important;
}

.menu-opt>.mat-menu-content {
  background-color: var(--white) !important;
  overflow: hidden;
  padding: 0 !important;
}

.menu-chi>.mat-menu-content {
  background-color: var(--white) !important;
}

.menu-chi-title {
  font-size: 12px;
  color: var(--primary-900);
}

.mat-menu-submenu-icon {
  color: var(--neutral-700) !important;
}
