.p-tooltip.tooltip-info .p-tooltip-text {
  background-color: var(--info-100) !important;
  border: 1px solid var(--info-300) !important;
  border-radius: 8px;
  font-family: var(--font) !important;
  color: var(--neutral-600) !important;
}

@mixin info-tooltip($height) {
  height: $height !important;
  background-color: var(--info-100) !important;
  border: 1px solid var(--info-300) !important;
  border-radius: 8px;
  font-family: var(--font) !important;
  color: var(--neutral-600) !important;
}

.info-img > .p-tooltip-text {
  @include info-tooltip(4.5rem);
}

.info-att-img > .p-tooltip-text {
  @include info-tooltip(5.5rem);
}

.info-img-tp > .p-tooltip-text{
  @include info-tooltip(4rem);
  width: 269px !important;
}

.p-tooltip.p-component.p-tooltip-top > .p-tooltip-text {
  padding: 0.5em 1.25rem !important;
}

.p-tooltip  {
  max-width: 27.5rem !important;
}

