.category-card {
  border-radius: 10px !important;
  height: 50px; 
  border: 1px solid var(--primary) !important; 

  &.primary {
    background-color: var(--primary-300) !important;
  }
}

.card-elevation {
  display: flex;
  width: 100%;
  box-shadow: 0px 3px 3px -2px rgba(69, 69, 69, 0.2), 0px 3px 4px rgba(69, 69, 69, 0.14), 0px 1px 8px rgba(69, 69, 69, 0.12);
  height: 14vh;
  max-height: 100px;
  min-height: 94px;
  border-radius: 8px;
  padding: 16px;

  .icon-card-elevation.up {
    display: none !important;
  }

  &[aria-expanded=true] {
    border: 1px solid var(--primary-400) !important;

    .icon-card-elevation.down {
      display: none !important;
    }

    .icon-card-elevation.up {
      display: contents !important;
    }
  }
}

.icon-card-elevation {
  color: var(--primary);
  margin-top: -14px;    
  font-size: 22px;
  display: contents !important;
}