:root {
  --white: #FFFFFF;
  --black: #000000;

  //PRIMARY COLORS
  --primary-900: #042077;
  --primary-800: #072E90;
  --primary-700: #0B41B3;
  --primary-600: #1058D7;
  --primary: #1673FA;
  --primary-400: #50A0FF;
  --primary-300: #8CC4FF;
  --primary-200: #BBE0FF;
  --primary-100: #DEF1FF;

  //NEUTRAL COLORS
  --neutral-900: #1F1F1F;
  --neutral-800: #4A4A4A;
  --neutral-700: #535353;
  --neutral-600: #717171;
  --neutral: #8B8B8B;
  --neutral-400: #B9B9B9;
  --neutral-300: #DCDCDC;
  --neutral-200: #EEEEEE;
  --neutral-100: #F9F9F9;

  //POSITIVE
  --positive-900: #205A06;
  --positive-800: #2D6D0B;
  --positive-700: #418711;
  --positive-600: #57A119;
  --positive: #70BC23;
  --positive-400: #9CD653;
  --positive-300: #BDEA78;
  --positive-200: #DCF8A7;
  --positive-100: #F8FFE6;

  //INFO
  --info-900: #003756;
  --info-800: #004C68;
  --info-700: #006982;
  --info-600: #008B9B;
  --info: #00B2B5;
  --info-400: #34D2C7;
  --info-300: #5DE8D3;
  --info-200: #94F7DF;
  --info-100: #DCFFF3;

  //WARNING
  --warning-900: #694D00;
  --warning-800: #7F6000;
  --warning-700: #9D7A00;
  --warning-600: #BC9600;
  --warning: #DBB300;
  --warning-400: #E9CC3A;
  --warning-300: #F4DF61;
  --warning-200: #FBEE96;
  --warning-100: #FFFCE4;

  //DANGER
  --danger-900: #690612;
  --danger-800: #7F0B10;
  --danger-700: #9D1511;
  --danger-600: #BC2A19;
  --danger: #D14526;
  --danger-400: #EB7B55;
  --danger-300: #F4A179;
  --danger-200: #FBC9A6;
  --danger-100: #FFF4E9;

  //SUPPORT
  --support-01: #4A4A4A;
  --support-02: #F4F4F4;
  --support-03: #E28921;
  --support-04: #FFC32A;
  --support-05: #BCD717;
  --support-06: #656AE8;
  --support-07: #E865C3;
  --support-08: #CF3C3C;
}

.white {
  color: var(--white) !important;
}

.primary-color {
  color: var(--primary) !important;
}

.danger-color {
  color: var(--danger) !important;
}

.secondary-color {
  color: var(--primary) !important;
}

.extended-secondary-darker {
  color: var(--primary-800) !important;
}

.extended-neutral-dark-active {
  color: var(--neutral-800) !important;
}

.secondary-neutral {
  color: #404040 !important;
}

.neutral-light {
  color: var(--neutral-400) !important;
}

.extended-neutral-light-active {
  color: var(--neutral-300) !important;
}

.black-color {
  color: var(--black) !important;
}
