.mat-select-value{
    font-family: var(--font) !important;
}

.mat-primary .mat-option.mat-selected:not(.mat-option-disabled):not(.mat-option-multiple) {
    background-color: var(--primary-300) !important;
    color: black !important;
}

.mat-option-multiple {
    color: black !important;
    font-family: var(--font) !important;
}

.mat-option-multiple{
    color: black !important;
    font-family: var(--font) !important;

    &.mat-active {
        background-color: transparent !important;
    }

    &.mat-selected {
        background-color: var(--primary-100) !important;
    }

    &:hover {
        background-color: var(--primary-100) !important;
    }
}

.mat-option {
    font-family: var(--font) !important;
}

// .mat-select-trigger {
//     display: block !important;
// }

.mat-select-arrow {
    color: rgb(33, 37, 41) !important;
}

.mat-form-field-underline {
    display: none !important;
}

.search-select{
    padding: 8px;

    .mat-form-field-wrapper {
      padding: 0px !important;
      margin: 0;
    }
}
