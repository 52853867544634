@font-face {
    font-family: 'Berlin Sans FB';
    src: url('../../../assets/fonts/BerlinSansFB.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Open Sans GO';
    src: url('../../../assets/fonts/OpenSans.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Inter';
    src: url('../../../assets/fonts/Inter.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'SF Pro Text';
    src: url('../../../assets/fonts/SF.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
  font-family: 'Gotham Rounded';
  src: url('../../../assets/fonts/GothamRounded.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}


:root {
    --font: 'Gotham Rounded', sans-serif;
    --font-logo: 'Gotham Rounded', sans-serif;
    --font-toastr: 'Gotham Rounded', sans-serif;
}

.heading {
    font-family: var(--font);
    font-style: normal;
    font-weight: 400;
    color: var(--neutral-800);
    line-height: 200%;

    &.title-3 {   
        font-size: 40px;
    }

    &.title-2 {
        font-size: 32px;
    }

    &.title-1 {
        font-size: 24px;
    }
}

.titles {
    font-family: var(--font);
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    color: var(--neutral-800);

    &.title-3 {   
        font-size: 32px;
    }

    &.title-2 {
        font-size: 24px;
    }

    &.title-1 {
        font-size: 20px;
    }
}

.subtitle {
    font-family: var(--font) !important;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    color: var(--neutral-800);

    &.subtitle-3 {   
        font-size: 24px;
    }

    &.subtitle-2 {
        font-size: 20px;
    }

    &.subtitle-1 {
        font-size: 16px;
    }
}

.paragraph {
    font-family: var(--font) !important;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;

    &.paragraph-2 {
        font-size: 16px;
    } 

    &.paragraph-1 {
        font-size: 14px;
    } 

    &.black-font {
        color: var(--neutral-800);
    }
}

.pass-13 {
    font-family: var(--font);
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 100%;
}

.caption {
    font-family: var(--font);
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 100%;
}

.overline {
    font-family: var(--font);
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 100%;
}

.semibold {
    font-family: var(--font) !important;
    font-weight: 600 !important;
}

.italic {
    font-style: italic !important;
}