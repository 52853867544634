.container-material {
  position: absolute;
  left: 50%;
  top: 50%;
  right: 1%;
  transform: translate(-50%, -50%);
}

.start-img {
  width: 100vw;
  height: 100vh;
}

.img-container {
  display: flex;
  align-items: center;
  height: 100%;
  flex-direction: column;
  background-size: cover;

  .logo {
    left: 50%;
    position: absolute; 
    top: 50%; 
    transform:translate(-50%, -50%); 
    width: 550px; 
  }
}

.img-mobile {
  height: 20vh;
  width: 100vw;  
  margin-bottom: 5vh;

  .img-container {
    justify-content: center;

    .logo-mobile {
      width: 200px;
    }
  }
}