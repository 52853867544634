.btn {
    border-radius: 100px !important;
    box-shadow: 0 0 0 0 !important;
    align-items: center;
    width: 100%;
    display: flex;

    &.fit-content {
      width: fit-content;

      @media screen and (max-width: 500px) {
        width: 100%;
      }
    }

    &.btn-primary {
        background: var(--primary) !important;
        border-color: transparent !important;

        &:hover {
            background-color: var(--primary-600) !important;
        }

        &:focus {
          border: 2px solid var(--primary-900) !important;
        }

        &:active {
            background-color: var(--primary-700) !important;
        }

        &:disabled {
            cursor: not-allowed !important;
            color: var(--neutral-400) !important;
            background: var(--neutral-200) !important;
        }
    }

    &.btn-secondary {
        background: var(--primary-100) !important;
        border-color: transparent !important;
        color: var(--primary-700);

        &:hover {
            background-color: var(--primary-200) !important;
            color: var(--primary-800) !important;
        }

        &:focus {
            background-color: var(--primary-100) !important;
            border: 2px solid var(--primary-900) !important;
            color: var(--primary-900) !important;
        }

        &:active {
            background-color: var(--primary-300) !important;
            color: var(--primary-900) !important;
        }

        &:disabled {
            cursor: not-allowed !important;
            background: var(--neutral-200) !important;
            color: var(--neutral-400) !important;
        }
    }

    &.btn-tertiary {
        border: 1px solid var(--primary-600);
        background-color: transparent !important;
        color: var(--primary-600);

        &:hover {
          background-color: transparent !important;
          border: 1px solid var(--primary-700) !important;
          color: var(--primary-700);
        }

        &:focus {
          border: 2px solid var(--primary-900) !important;
          color: var(--primary-600);
        }

        &:active {
          border: 1px solid var(--primary-700) !important;
          color: var(--primary-700) !important;
        }

        &:disabled {
            cursor: not-allowed !important;
            border: 1px solid var(--neutral-200);
            color: var(--neutral-400) !important;
        }
    }

    &.btn-positive {
      background: var(--positive) !important;
      border-color: transparent !important;

      &:hover {
          background-color: var(--positive-600) !important;
      }

      &:focus {
        border: 2px solid var(--positive-900) !important;
      }

      &:active {
          background-color: var(--positive-700) !important;
      }

      &:disabled {
          cursor: not-allowed !important;
          color: var(--neutral-400) !important;
          background: var(--neutral-200) !important;
      }
    }

    &.btn-danger-complete {
      background: var(--danger) !important;
      border-color: transparent !important;

      &:hover {
          background-color: var(--danger-600) !important;
      }

      &:focus {
        border: 2px solid var(--danger-900) !important;
      }

      &:active {
          background-color: var(--danger-700) !important;
      }

      &:disabled {
          cursor: not-allowed !important;
          color: var(--neutral-400) !important;
          background: var(--neutral-200) !important;
      }
    }

    &.text-button{
      color: var(--primary-600);

      &:hover {
        color: var(--primary-700);
      }

      &:focus {
        border: 2px solid var(--primary-900) !important;
        color: var(--primary-600);
      }

      &:active {
        color: var(--primary-700) !important;
      }

      &:disabled {
        cursor: not-allowed !important;
        background-color: transparent !important;
        color: var(--neutral-400) !important;
      }
    }

    &.btn-danger {
      background-color: var(--danger) !important;

      &:hover {
        background-color: var(--danger-600) !important;
      }

      &:disabled {
        cursor: not-allowed !important;
        background-color: var(--neutral-200) !important;
        color: var(--neutral-400) !important;
      }
    }

    &.btn-success {
      background-color: var(--positive) !important;

      &:hover {
        background-color: var(--positive-600) !important;
      }

      &:disabled {
        cursor: not-allowed !important;
        background-color: var(--neutral-200) !important;
        color: var(--neutral-400) !important;
      }
    }

    &.btn-secondary-disabled {
        background: var(--primary-100) !important;
        border-color: transparent !important;
        cursor: not-allowed !important;
    }

}

.border-white {
    border: 1px solid white !important;
}

.mat-button-wrapper {
  font-family: var(--font) !important;
}