mat-checkbox {
    box-sizing: border-box;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
}

.mat-checkbox-checked .mat-checkbox-background {
    background-color: var(--primary) !important;
}

.mat-checkbox-disabled {
    cursor: not-allowed !important;
    background-color: var(--neutral-100) !important;
}

.label-checkbox {
    height: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 8px;
}

.mat-checkbox-checked.mat-accent.mat-checkbox-disabled .mat-checkbox-background, .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background {
    background-color: var(--neutral-300) !important;
}

.mat-checkbox-checked.mat-accent .mat-checkbox-ripple .mat-ripple-element {
    opacity: 0.03 !important;
    background-color: var(--primary) !important;
}

.mat-pseudo-checkbox-checked, .mat-pseudo-checkbox-indeterminate {
    background-color: var(--primary) !important; // Altere para a cor desejada
    border-color: var(--primary) !important; // Altere para a cor desejada
}

.mat-checkbox.mat-accent {
  .mat-checkbox-ripple .mat-ripple-element {
      background-color: var(--primary) !important;
  }
}
