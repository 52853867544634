.mat-table {
  font-family: var(--font) !important;
  box-shadow: none !important;
}

.mat-sort-header-content, .mat-header-cell {
  font-family: var(--font) !important;
  color: var(--neutral-800) !important;
  font-weight: 600 !important;
  font-size: 16px !important;
  line-height: 150% !important;
  text-align: start !important;
}

.mat-table .mat-cell,
.mat-table .mat-header-cell {
  border-bottom: none !important;
}

.mat-header-row {
  position: relative !important;
}

.mat-header-row::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  border-bottom: 1px solid var(--primary-300) !important;
}

table {
  border-bottom: 1px solid var(--neutral-400) !important;
}

.mat-cell.cdk-cell {
  font-family: var(--font) !important;
  font-weight: 400 !important;
  font-size: 16px !important;
  line-height: 150% !important;
}

.icon-asc {
  font-size: 16px !important;
  margin-top: 10px;
}

.mat-paginator-page-size-label {
  margin-right: 20px !important;
  font-size: 16px;
  color: var(--neutral-800);
}

.mat-paginator-page-size-select {
  .mat-form-field-flex {
    height: 0px !important;
    padding-bottom: 34px;

    .mat-select-value {
      font-size: 16px;
      color: var(--neutral-700);
    }
  }
}

.mat-paginator-range-label {
  font-size: 16px;
  color: var(--neutral-800);
}

.mat-option-text {
  font-size: 16px;
}

.column-5 {
  width: 5vw !important;
  max-width: 5vw !important;
}

.column-10 {
  width: 10vw !important;
  max-width: 10vw !important;
}

.column-12 {
  width: 12vw !important;
  max-width: 12vw !important;
}

.column-15 {
  width: 15vw !important;
  max-width: 15vw !important;
}

.column-16 {
  width: 16vw !important;
  max-width: 16vw !important;
}

.column-17 {
  width: 17vw !important;
  max-width: 17vw !important;
}

.column-20 {
  width: 20vw !important;
  max-width: 20vw !important;
}

.column-25 {
  width: 25vw !important;
  max-width: 25vw !important;
}

.column-30 {
  width: 30vw !important;
  max-width: 30vw !important;
}

.column-35 {
  width: 35vw !important;
  max-width: 35vw !important;
}

.column-40 {
  width: 40vw !important;
  max-width: 40vw !important;
}

.column-45 {
  width: 45vw !important;
  max-width: 45vw !important;
}

.column-50 {
  width: 50vw !important;
  max-width: 50vw !important;
}

.column-55 {
  width: 55vw !important;
  max-width: 55vw !important;
}

.column-60 {
  width: 60vw !important;
  max-width: 60vw !important;
}

.column-65 {
  width: 65vw !important;
  max-width: 65vw !important;
}

.column-70 {
  width: 70vw !important;
  max-width: 70vw !important;
}

.column-75 {
  width: 75vw !important;
  max-width: 75vw !important;
}

.column-85 {
  width: 85vw !important;
  max-width: 85vw !important;
}

.column-90 {
  width: 90vw !important;
  max-width: 90vw !important;
}

.column-95 {
  width: 95vw !important;
  max-width: 95vw !important;
}

.card-mobile {
  box-shadow: 0px 3px 3px -2px rgba(69, 69, 69, 0.2),
    0px 3px 4px rgba(69, 69, 69, 0.14), 0px 1px 8px rgba(69, 69, 69, 0.12);
  border-radius: 16px;
  margin-top: 15px;
  margin-left: 15px;
  padding: 0.75rem;
}

.card-row-mobile {
  box-shadow: 0px 3px 3px -2px rgba(69, 69, 69, 0.2),
    0px 3px 4px rgba(69, 69, 69, 0.14), 0px 1px 8px rgba(69, 69, 69, 0.12);
  border-radius: 16px;
  display: flex;
  margin-top: 15px;
}

.card-mobile-content {
  margin-top: 12px;
  margin-left: 32px;
  flex-direction: column;
  margin-bottom: 12px;
  width: 100%;
}

.card-mobile-content-circle {
  flex-direction: column;
  margin-bottom: 12px;
  width: 100%;
}

.card-selected {
  box-shadow: 0px 3px 3px -2px rgba(#1673fa, 0.8),
    0px 3px 4px rgba(#1673fa, 0.56), 0px 1px 8px rgba(#1673fa, 0.48);
}

.margin-btm {
  margin-bottom: -6px !important;
}

.pagination-card {
  margin-top: 15px;
  display: flex;
  justify-content: center;
}

.w-90 {
  width: 90%;
}

.column-category {
  padding-right: 15px !important;

  & .mat-chip-list-wrapper {
    flex-wrap: nowrap !important;
    overflow: hidden !important;
  }
}

.column-category-20 {
  padding-right: 15px !important;

  & .mat-chip-list-wrapper {
    flex-wrap: nowrap !important;
    width: 20vw;
    overflow: hidden !important;
  }
}

// drag and drop
.mat-row {
  cursor: move;
}

.cdk-drag-placeholder {
  opacity: 0;
}

.cdk-drag-preview {
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
    0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0 16px;
}

.cdk-drag-animating {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.mat-row.cdk-drag-placeholder {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.disabled {
  cursor: not-allowed;
}
