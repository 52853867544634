.mat-form-field-appearance-outline:not(.mat-form-field-invalid) {
  .mat-form-field-outline {
    color: var(--neutral-400);
  }

  .mat-form-field-outline-thick {
    color: var(--primary-400);
  }

  &.mat-form-field-disabled {
    .mat-form-field-outline {
      color: var(--neutral-300) !important;
    }
  }
}

.mat-form-field.mat-focused:not(.mat-form-field-invalid) {
    .mat-form-field-outline, .mat-form-field-label, .mat-form-field-required-marker {
      color: var(--primary) !important;
    }
}

mat-form-field {
    width: 100%;
}

.mat-form-field-flex{
    height: 66px;
}

.text-area {
    .mat-form-field-flex{
        height: auto;
    } 
}

input.mat-input-element {
    height: 24px;
    color: var(--neutral-900) !important;

    &:disabled {
      color: var(--neutral-600) !important;
    }
}

.mat-input {
    font-family: var(--font) !important;
    line-height: 1.5 !important;
}

.mat-form-field-invalid {  
    .mat-form-field-label, .mat-form-field-outline-thick {
      color: var(--danger-700) !important;
    }
}

.mat-label-error {
    color: var(--danger-700) !important;
}

.custom-label-input {
    border-radius: 8px !important;
    background-color: var(--primary-100) !important;
    font-family: var(--font) !important;
    color: var(--primary-900) !important;
}

.mat-chip-area{   
    .mat-form-field-flex{
        height: auto!important;
        max-height: 20vh!important;
    }

    .mat-chip-list-wrapper {
        height: auto;
        max-height: 15vh;
        overflow-y: auto;
        overflow-x: hidden;
    }

    .mat-select-trigger{
        height: auto;
    }
}


