@import "./colors.scss";
@import "./fonts.scss";
@import "./components/icons.scss";
@import "./components/checkbox.scss";
@import "./components/radio-button.scss";
@import "./components/switch.scss";
@import "./components/input.scss";
@import "./components/spinner.scss";
@import "./components/select.scss";
@import "./components/menu.scss";
@import "./components/card.scss";
@import "./components/button.scss";
@import "./components/menu-nav.scss";
@import "./components/table.scss";
@import "./components/input-files.scss";
@import "./components/tooltip.scss";
@import "./components/dialog.scss";
@import "./components/datepicker.scss";
@import "./components/panel.scss";
@import "./components/nav-tabs.scss";
@import "./components/expansion-panel.scss";
@import "./components/character-count.scss";
@import "./components/login-image.scss";

* {
  font-family: var(--font);
}

.status {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 0.5rem;

  &.closed {
    background-color: var(--neutral-200);
  }

  &.refused {
    background-color: var(--danger-600);
  }

  &.active,
  &.sent,
  &.accept {
    background-color: var(--positive);
  }

  &.pending {
    background-color: var(--warning);
  }
}

.status-container {
  display: flex;
  align-items: center;
  padding-top: 0.8rem;
}

.status.closed {
  background-color: var(--neutral-200);
}

.status.refused {
  background-color: var(--danger-600);
}

.status {
  &.active,
  &.sent,
  &.accept {
    background-color: var(--positive);
  }
}

.status.pending {
  background-color: var(--warning);
}

.status.shipped {
  background-color: var(--primary);
}

.margin-15 {
  margin: 10px;
  margin-top: 20px;
}

.form-div {
  margin-bottom: 30px;
  border-bottom: 1px solid var(--primary-100);
}

.truncate-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  overflow-wrap: break-word;
  max-width: 95%;
}

.truncate-words {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  overflow-wrap: break-word;
}

.jump-line-words {
  text-overflow: ellipsis;
  overflow-wrap: break-word;
  max-width: 100%;
}

@media only screen and (max-width: 768px) {
  .truncate-words {
    max-width: 85%;
  }
}

.w-max-content {
  width: max-content;
}

.g-16 {
  gap: 16px;
}

.mat-dialog-container {
  border-radius: 20px !important;
}

p-dialog {
  .p-dialog-content {
    &::-webkit-scrollbar {
      width: 6px;
    }

    &::-webkit-scrollbar-thumb {
      background: rgb(153, 153, 153);
      border-radius: 10px;
    }
  }

  .mat-form-field-flex:has(.mat-form-field-prefix) { 
    .mat-form-field-outline-start {
      width: 40px !important;
    }
  }
}

mat-stepper {
  .mat-step-icon {
    background-color: #f9f9f9 !important;
    color: #535353 !important;
    font-weight: 700;
    height: 48px !important;
    width: 48px !important;
  }

  .mat-step-icon-selected {
    background-color: var(--primary-100) !important;
  }

  .mat-stepper-horizontal-line,
  .mat-horizontal-stepper-header:before {
    top: 23px !important;
  }

  .mat-horizontal-stepper-header:after {
    top: 23px !important;
    width: calc(50% - 24px) !important;
  }

  .mat-horizontal-stepper-header,
  .mat-step-label,
  .mat-horizontal-content-container {
    padding: 0px !important;
  }

  .mat-step-header,
  .cdk-program-focused {
    background-color: #fff !important;
  }

  .mat-horizontal-stepper-header-container {
    margin: 1rem 0 0 0 !important;
    width: 100% !important;
    padding: 0 5rem;
  }
}
