.anexo-area {
    border-radius: 12px;
    border: 1px solid var(--primary-100) !important;
}

.margin-15 {
    margin: 15px;
}

.anexo-file {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background-color: var(--primary-100);
    border-radius: 8px;
    margin-top: 8px;
    color: var(--primary-800);
    
    .icon-file {
        font-size: 20px;
        margin-top: 14px;
        margin-left: 8px;
    }
}


.img-anexo {
    margin: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.container-img {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.img-responsive {
    display: block;
    max-width: 180px;
    max-height: 180px;
    width: auto;
    height: auto;
    object-fit: contain;
    border: solid white;
    background-color: white;
}