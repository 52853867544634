.p-dialog {
  border-radius: 16px !important;
  overflow: auto;
  width: 100vw;
}

@media screen and (min-width: 576px) {
  .large {
    .p-dialog {
      width: 80vw;
    }
  }

  .medium-l {
    .p-dialog {
      width: 70vw;
    }
  }

  .medium {
    .p-dialog {
      width: 50vw;
    }
  }
}

@media (max-width: 768px) { 
  .p-dialog {
    height: 60vh; 
  }
}

.cdk-global-overlay-wrapper, .cdk-overlay-container {
  z-index: 99999 !important;
}
