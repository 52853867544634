.mat-radio-button.mat-accent {
  .mat-radio-outer-circle {
    border-color: var(--neutral-700);
  }

  .mat-radio-inner-circle {
    background-color: var(--primary) !important;
  }
  
  &.mat-radio-checked .mat-radio-outer-circle {
    border-color: var(--primary) !important;
  }
}

.radio-margin {
    margin-left: 5vw;
}

.radio-label {
    margin-top: 10px;
}

.mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element {
    background-color: var(--primary) !important;
}

.mat-radio-disabled {
  .mat-radio-outer-circle, mat-radio-inner-circle {
    cursor: not-allowed !important;
    border-color: var(--neutral-800) !important;
    opacity: 0.5;
  }

  .mat-radio-label-content {
    color: var(--neutral-800) !important;
    opacity: 0.5;
  }
}
