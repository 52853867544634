.mat-menu-panel {
  font-family: var(--font);

  &.notifications {
    min-width: 30vw !important;
  }

  &.home-card {
    min-width: calc(100vw - 30px) !important;
  }
}

.cdk-overlay-pane {
  margin-top: 4px;
}

@media screen and (min-width: 576px) {
  .mat-menu-panel {
    &.notifications {
      position: relative;
      right: -79px;
    }

    &.home-card {
      min-width: calc(25vw - 30px) !important;
    }
  }
}