.mat-calendar-body-selected {
    background-color: var(--primary-100) !important;
    color: var(--neutral-900) !important;

}


.mat-calendar-body-today {
    background-color: white !important;
    border-color: var(--primary) !important;

    &.mat-calendar-body-selected {
        background-color: var(--primary-100) !important;
        color: var(--neutral-900) !important;
        border-color: transparent !important;
    }
}

.mat-datepicker-toggle-default-icon {
    color: var(--neutral) !important;
}
.mat-button-wrapper,  .mat-calendar-body{
    font-family: var(--font) ;
}

.p-highlight {
    border: 2px solid var(--primary) !important;
    background-color: transparent !important;
}

.p-datepicker table td.p-datepicker-today>span:not(.p-highlight) {
    background-color: transparent !important;
    border: 2px solid transparent !important;
}

.p-link{
  font-family: var(--font) !important;
}
