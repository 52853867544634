 input[type="checkbox"].switchButton {
  cursor: pointer;
  position: relative;
  width: 51px;
  height: 35px;
  -webkit-appearance: none;
  background: var(--neutral-400);
  outline: none;
  border-radius: 99px;
  transition: .5s;
  padding-top: 3px;
  padding-left: 5px;

  &:disabled {
    cursor: not-allowed;
    background: var(--neutral-300);
  }
}

input:checked[type="checkbox"].switchButton {
  background-color: var(--primary);
  cursor: pointer;

  &:disabled {
    cursor: not-allowed;
    background-color: var(--primary-200);
  }
}

input[type="checkbox"].switchButton:before {
  content: '';
  position: absolute;
  width: 27px;
  height: 27px;
  border-radius: 20px;
  top: 4;
  left: 4;
  background-color: white;
  transition: .5s;
}

input:checked[type="checkbox"].switchButton:before {
  left: 20px;
}

.label-st {
  font-family: var(--font);
  margin-left: 8px !important;
  margin-bottom: 0 !important;
}

.switchPosition{
  margin:-3px 0px 3px 3px
}
