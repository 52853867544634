.loader {
    background:conic-gradient(from 180deg at 50% 50%, var(--neutral-900) 0deg, #d9d9d9 360deg);
    -webkit-mask:radial-gradient(farthest-side,#0000 calc(100% - 5px),#fff 0);
            mask:radial-gradient(farthest-side,#0000 calc(100% - 5px),#fff 0);
    border-radius: 50%;
    position: fixed;
    inset : calc(50% - 50px);
    animation: rot 1s linear infinite;
    width: 50px;
    height: 50px;
    z-index: 2193193129313212139193913219;
  }

  @keyframes rot {
    100% { transform: rotate(-360deg); }
  }
