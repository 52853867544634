.mat-ink-bar {
  background-color: var(--primary) !important;
}

.mat-tab-label {
  font-family: var(--font) !important;
  font-size: 16px !important;
  color: var(--neutral-600) !important;
}

.mat-tab-label.mat-tab-label-active {
    font-weight: bold !important;
    color: var(--neutral-800) !important;
    opacity: 1;
}
